/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* stylelint-disable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/* stylelint-enable @amzn/awsui-no-motion-outside-of-mixin, selector-combinator-disallowed-list, selector-pseudo-class-no-unknown, selector-class-pattern */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/* Style used for links in slots/components that are text heavy, to help links stand out among 
surrounding text. (WCAG F73) https://www.w3.org/WAI/WCAG21/Techniques/failures/F73#description */
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
/*
 Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 SPDX-License-Identifier: Apache-2.0
*/
.awsui_root_18582_1r1q6_93:not(#\9) {
  display: flex;
}

.awsui_child_18582_1r1q6_97:not(#\9) {
  /* used in test-utils */
}

.awsui_child_18582_1r1q6_97:not(#\9):empty {
  display: none;
}

/*
 * Horizontal variant
 */
.awsui_horizontal_18582_1r1q6_108:not(#\9) {
  flex-direction: row;
  flex-wrap: wrap;
}
.awsui_horizontal-xxxs_18582_1r1q6_112:not(#\9) {
  gap: var(--space-xxxs-x2jyz4, 2px);
}
.awsui_horizontal-xxs_18582_1r1q6_115:not(#\9) {
  gap: var(--space-xxs-1wmjb0, 4px);
}
.awsui_horizontal-xs_18582_1r1q6_118:not(#\9) {
  gap: var(--space-xs-e7dyai, 8px);
}
.awsui_horizontal-s_18582_1r1q6_121:not(#\9) {
  gap: var(--space-s-agghyv, 12px);
}
.awsui_horizontal-m_18582_1r1q6_124:not(#\9) {
  gap: var(--space-m-6hr7pn, 16px);
}
.awsui_horizontal-l_18582_1r1q6_127:not(#\9) {
  gap: var(--space-l-q5p9hz, 20px);
}
.awsui_horizontal-xl_18582_1r1q6_130:not(#\9) {
  gap: var(--space-xl-v605rp, 24px);
}
.awsui_horizontal-xxl_18582_1r1q6_133:not(#\9) {
  gap: var(--space-xxl-4o4j98, 32px);
}

/*
 * Vertical variant
 */
.awsui_vertical_18582_1r1q6_140:not(#\9) {
  flex-direction: column;
}
.awsui_vertical-xxxs_18582_1r1q6_143:not(#\9) {
  row-gap: var(--space-xxxs-x2jyz4, 2px);
}
.awsui_vertical-xxs_18582_1r1q6_146:not(#\9) {
  row-gap: var(--space-xxs-1wmjb0, 4px);
}
.awsui_vertical-xs_18582_1r1q6_149:not(#\9) {
  row-gap: var(--space-xs-e7dyai, 8px);
}
.awsui_vertical-s_18582_1r1q6_152:not(#\9) {
  row-gap: var(--space-scaled-s-eyzx9z, 12px);
}
.awsui_vertical-m_18582_1r1q6_155:not(#\9) {
  row-gap: var(--space-scaled-m-wu03sm, 16px);
}
.awsui_vertical-l_18582_1r1q6_158:not(#\9) {
  row-gap: var(--space-scaled-l-udxpzh, 20px);
}
.awsui_vertical-xl_18582_1r1q6_161:not(#\9) {
  row-gap: var(--space-scaled-xl-6zb5a6, 24px);
}
.awsui_vertical-xxl_18582_1r1q6_164:not(#\9) {
  row-gap: var(--space-scaled-xxl-mq2qty, 32px);
}

.awsui_align-center_18582_1r1q6_168:not(#\9) {
  align-items: center;
}

.awsui_align-start_18582_1r1q6_172:not(#\9) {
  align-items: start;
}

.awsui_align-end_18582_1r1q6_176:not(#\9) {
  align-items: end;
}